// src/web3ModalConfig.ts
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/vue';

const projectId = '5f931bf56115df8e871c07ad48050535';

// 2. Set chains





const EthereumMainne = {
  chainId: 1,
  name: 'Ethereum Mainne',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: process.env.VUE_APP_Ethereum_RPC,
}

const Arbitrum = {
  chainId: 42161,
  name: 'Arbitrum One',
  currency: 'ETH',
  explorerUrl: 'https://explorer.arbitrum.io',
  rpcUrl: process.env.VUE_APP_ARB_RPC,
}


const BNBChain = {
  chainId: 56,
  name: 'BNB Chain',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com/',
  rpcUrl: process.env.VUE_APP_BSC_RPC,
}

const Polygon = {
  chainId: 137,
  name: 'Polygon',
  currency: 'MATIC',
  explorerUrl: 'https://polygonscan.com',
  rpcUrl: process.env.VUE_APP_Polygon_RPC,
};


// 3. Create your application's metadata object
const metadata = {
  name: 'mixezs.com',
  description: 'A decentralized platform for anonymous token deposits and withdrawals.',
  url: 'https://mixezs.com/', // url must match your domain & subdomain
  icons: ['https://mixezs.com/favicon.ico'],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: false,
  defaultChainId: 137, // Coinbase SDK Default Chain ID
  auth: {
    email: false, // default to true
  }
});

// 5. Create a Web3Modal instance
console.log(EthereumMainne, BNBChain, Polygon, Arbitrum)
const modal = createWeb3Modal({
  ethersConfig,
  chains: [EthereumMainne, BNBChain, Polygon, Arbitrum],
  allowUnsupportedChain: false,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: false, // Optional - false as default
  featuredWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', //mask
    '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',//BIT
    'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b', //qur
    '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',//BIT
    '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66',//TP
  ],

  // includeWalletIds: [
  //   'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
  //   '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66',
  //   'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b',
  //   '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662'
  // ],
  // excludeWalletIds: [
  //   'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b',//PAICHU
  //   'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa'
  // ]

});





export { modal };
