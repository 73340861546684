<template>
	<div element-loading-background="#6a4c4185" class="footer">
		<div class="main">
			<el-image style="height: 40px; width: 99px" src="/icons/logo.png" fit="contain" />
			<div class="left">
				<div class="chain" @click="isConnected ? open({ view: 'Networks' }) : open({ view: 'Connect' })">
					<div class="Connects"><el-image style="height: 24px" :src="isConnected ? 'https://webgallery.oss-cn-beijing.aliyuncs.com/token/' + imgchainId + '/' + nativetoken.toLowerCase() + '.png' : '/icons/Icon.png'" fit="contain" /></div>
					<div class="chaintxt">
						<span>{{ isConnected ? Ethereum : "Link wallet" }}</span>
					</div>
				</div>
				<div :class="'Connect ' + (isConnected ? 'Connectis' : '')" @click="isConnected ? open({ view: 'Account' }) : open({ view: 'Connect' })">
					<el-image style="height: 24px" :src="isConnected ? '/icons/metamask.png' : '/icons/nometamask.png'" fit="contain" />
				</div>
				<div class="dropdown">
					<div class="dropdown-button">
						<el-avatar size="small" :src="value" />
					</div>
					<div class="dropdown-content">
						<div class="langlist">
							<el-avatar v-for="(item, index) in cities" :key="index" size="small" :src="item.img" @click="switchlanguage(item.img, item.lang)" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { modal } from "@/api/web3ModalConfig";
	import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers/vue";
	import { useStore } from "vuex";
	import { watch, ref, onMounted, getCurrentInstance } from "vue";
	import chainData from "@/assets/mixezs/token.json";
	const { open, close } = useWeb3Modal();
	const { address, chainId, isConnected } = useWeb3ModalAccount();
	const { proxy } = getCurrentInstance();
	const value = ref("/icons/English.png");
	const cities = [
		{
			lang: "en",
			img: "/icons/English.png",
		},
		{
			lang: "fr",
			img: "/icons/Français.png",
		},
		{
			lang: "kr",
			img: "/icons/Konglish.png",
		},
		{
			lang: "zh",
			img: "/icons/zh.png",
		},
		{
			lang: "es",
			img: "/icons/Spanish.png",
		},
		{
			lang: "tw",
			img: "/icons/tw.png",
		},
		{
			lang: "th",
			img: "/icons/Thai.png",
		},
	];
	const store = useStore();
	const name = ref(process.env.VUE_APP_NAME);

	store.commit("Whethertolink", isConnected.value);

	const switchlanguage = async (img, lang) => {
		try {
			value.value = img;
			proxy.$i18n.locale = lang;
		} catch (error) {
			console.log(error);
		}
	};
	const nativetoken = ref("");
	const imgchainId = ref("");
	const Ethereum = ref("");
	const setdata = () => {
		nativetoken.value = isConnected.value ? chainData[Number(chainId.value)]["nativetoken"] : chainData[1]["nativetoken"];
		imgchainId.value = isConnected.value ? Number(chainId.value) : "1";
		Ethereum.value = isConnected.value ? chainData[Number(chainId.value)]["name"] : chainData[1]["name"];
	};

	onMounted(() => {
		const userLanguage = navigator.language || navigator.languages[0];
		if (userLanguage.includes("zh")) {
			switchlanguage("/icons/zh.png", "zh");
			proxy.$message.success("根据您的系统语言,已自动为你切换位中文");
		}
		setdata();
	});
	watch(isConnected, (newVal, oldVal) => {
		store.commit("Whethertolink", isConnected.value);
	});

	watch(chainId, (newVal, oldVal) => {
		setdata();
	});
	watch(address, (newVal, oldVal) => {
		store.commit("setSinghash", null);
		store.commit("setSingtext", null);
		store.commit("setVerify", false);
	});
</script>
<style scoped>
	.chain {
		display: flex;
		flex-wrap: nowrap;
	}
	.chaintxt {
		background: #121331;
		font-size: 13px;
		text-transform: uppercase;
		font-weight: bold;
		padding: 5px 10px;
		display: flex;
		border-radius: 0 4px 4px 0;
		align-items: center;
	}

	.dropdown {
		position: relative;
		display: inline-block;
	}

	.dropdown-button {
		font-size: 0;
		background: #121331;
		border-radius: 4px;
		box-shadow: 0 0 0 1px #33344e inset !important;
		padding: 5px;
		border-radius: 4px;
		cursor: pointer;
	}
	.Connects {
		font-size: 0;
		background: #121331;
		padding: 5px;
		width: 34px;
		border-radius: 4px 0 0 4px;
		box-shadow: 0 0 0 1px #33344e inset;
	}
	.Connect {
		font-size: 0;
		background: #121331;
		width: 34px;
		height: 34px;
		padding: 5px;
		border-radius: 4px;
		box-shadow: 0 0 0 1px #33344e inset;
	}
	.Connectis {
		box-shadow: 0 0 0 1px #3b82f6 inset;
	}

	.dropdown-content {
		display: none;
		position: absolute;
		z-index: 1;
		top: 100%;
		left: 0;
	}

	.dropdown:active .dropdown-content,
	.dropdown:hover .dropdown-content {
		display: block;
	}
	.langlist {
		margin-top: 5px;
		display: flex;
		flex-direction: column;
		box-shadow: 0 0 0 1px #33344e inset !important;
		background: #121331;
		border-radius: 4px;
		padding: 5px;
		gap: 8px;
	}

	.left {
		display: flex;
		flex-wrap: nowrap;

		align-items: center;
		gap: 10px;
	}
	.footer {
		display: flex;
		width: 100vw;
		height: 7vh;
		top: 0;
		background-color: #040726;
		z-index: 99;
		align-items: center;
	}
	.footer .main {
		width: 95vw;
		color: aliceblue !important;
		margin: auto;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
	}

	@media (min-width: 700px) {
		.footer {
			height: 72.1875px;
		}
		.footer .main {
			width: 700px;
		}
	}
</style>
<style>
	@media (max-width: 500px) {
		:root {
			--wui-spacing-xs: 4px !important;
		}
	}
	button:disabled {
		color: #fff !important;
	}

	wui-flex > wui-text {
		color: aliceblue;
	}
	.el-loading-spinner .path {
		stroke: #6a4c41 !important;
	}
</style>
