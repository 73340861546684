<template>
	<div class="mixzes">
		<div>
			<myfooter />
			<div class="Mixer">
				<div class="collapse">
					<el-alert type="success" effect="dark" center :closable="false">
						<template #title>
							<div class="Mixertitle">
								<span>{{ $t("Mixezs.titleone") }}</span>
								<el-image style="height: 22px; width: 53px" src="/icons/beosin.png" fit="contain" />
								<span>{{ $t("Mixezs.titletwo") }}</span>
								<a href="https://www.beosin.com/audits/Mixezs_202409101130.pdf" target="_blank" rel="noopener noreferrer">{{ $t("Mixezs.titlethree") }}</a>
							</div>
						</template>
					</el-alert>
				</div>

				<box />

				<div class="collapse">
					<el-collapse v-model="activeName" accordion>
						<el-collapse-item :title="$t('Mixezs.MixezsPrivacyTransfer')" name="1">
							<div>
								<div>{{ $t("Mixezs.MixezsIntro") }}</div>
							</div>
						</el-collapse-item>
						<el-collapse-item :title="$t('Mixezs.CoreHighlights')" name="2">
							<h4>{{ $t("Mixezs.SeamlessPrivacyProtection") }}</h4>
							<div>{{ $t("Mixezs.EffectiveTrackingPrevention") }}</div>
							<h4>{{ $t("Mixezs.DeepAnonymityVerification") }}</h4>
							<div>{{ $t("Mixezs.AdvancedSignatureVerification") }}</div>
							<h4>{{ $t("Mixezs.HighlySecureVerificationLogic") }}</h4>
							<div>{{ $t("Mixezs.VerifierContractDesign") }}</div>
							<h4>{{ $t("Mixezs.AuditLevelSecurity") }}</h4>
							<div>{{ $t("Mixezs.ThirdPartyAudit") }}</div>
						</el-collapse-item>
						<el-collapse-item :title="$t('Mixezs.ContractFunctions')" name="3">
							<h4>{{ $t("Mixezs.DepositContract") }}</h4>
							<div>{{ $t("Mixezs.DepositMechanism") }}</div>
							<h4>{{ $t("Mixezs.VerificationContract") }}</h4>
							<div>{{ $t("Mixezs.SignatureVerification") }}</div>
						</el-collapse-item>
						<el-collapse-item :title="$t('Mixezs.MixezsAdvantages')" name="4">
							<h4>{{ $t("Mixezs.TopPrivacyProtection") }}</h4>
							<div>{{ $t("Mixezs.InnovativeDesign") }}</div>
							<h4>{{ $t("Mixezs.SecurityAndDecentralization") }}</h4>
							<div>{{ $t("Mixezs.RigorousAudit") }}</div>
							<h4>{{ $t("Mixezs.AntiAttackDesign") }}</h4>
							<div>{{ $t("Mixezs.CryptographicMechanisms") }}</div>
						</el-collapse-item>
					</el-collapse>
				</div>
			</div>
		</div>
		<div class="bottom">
			<div class="bottommain">
				<div class="Donate">
					<div class="textflex">
						<span>Donation:</span><span>{{ Donation }}</span>
					</div>
					<div class="textflex">
						<span>Mixzes.version:</span><span>{{ packageJson.version }}</span>
					</div>
				</div>
				<div class="fonlist">
					<a :href="tgurl" target="_blank" rel="noopener noreferrer"><el-image style="height: 24px; width: 24px" src="/icons/tg.png" fit="contain" /></a>
					<a href="https://x.com/mixezs" target="_blank" rel="noopener noreferrer"><el-image style="height: 24px; width: 24px" src="/icons/x.png" fit="contain" /></a>
					<a href="https://github.com/dexcpro/mixezs-ui" target="_blank" rel="noopener noreferrer"> <el-image style="height: 24px; width: 24px" src="/icons/gitub.png" fit="contain" /></a>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, onMounted } from "vue";
	import box from "@/components/mixer/box";
	import myfooter from "@/components/myfooter";
	import packageJson from "../../package.json";
	const activeName = ref("1");
	const Donation = ref(process.env.VUE_APP_DONA);
	const tgurl = ref();
	onMounted(() => {
		const userLanguage = navigator.language || navigator.languages[0];
		tgurl.value = userLanguage.includes("zh") ? "https://t.me/mixezs_hub/17541" : (tgurl.value = "https://t.me/mixezs_hub/17545");
	});
</script>
<style scoped>
	.fonlist {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		font-size: 0;
		gap: 15px !important;
	}
	.textflex {
		display: flex;
		flex-wrap: nowrap;
		gap: 3px;
	}

	.textflex span:last-of-type {
		color: #1541fa;
	}
	.mixzes {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.Donate {
		display: flex;
		flex-direction: column;
		font-size: 12px;
		gap: 5px;
	}
	.bottom {
		width: 100vw;
	}

	.bottommain {
		display: flex;
		flex-wrap: unset;
		flex-direction: column;
		gap: 10px;
		width: 95vw;
		margin: 0 auto;
		padding: 20px 0;
		color: #fff;
		justify-content: center;
		align-items: center;
	}
	.Mixer {
		width: 100vw;
	}
	.el-alert--success.is-dark {
		background: #121331 !important;
	}
	.Mixertitle {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		font-size: 8px;
		gap: 5px;
	}
	@media (min-width: 700px) {
		.bottommain {
			width: 700px;
			display: flex;
			flex-direction: unset;
			flex-wrap: nowrap;
			justify-content: space-between;
		}
		.Mixertitle {
			font-size: 16px;
		}
	}
</style>

<style scoped>
	.el-collapse {
		padding: 15px;
		border-radius: 10px;
		background: #121331;
		border: 1px solid #33344e;
	}
	.collapse {
		width: 95vw;
		padding-top: 20px;
		margin: auto;
	}

	h3,
	h4,
	h5 {
		color: #fff !important;
	}
	@media (min-width: 700px) {
		.collapse {
			width: 700px;
		}
	}
</style>
<style>
	.el-collapse-item__wrap {
		background-color: #121331 !important;
		border-bottom: 1px solid #33344e !important;
	}
	.el-collapse-item__content {
		color: #91a7c8 !important ;
	}

	.el-collapse-item__header.is-active,
	.el-collapse-item__header {
		background-color: #121331 !important;
		border-bottom: 1px solid #33344e !important;
		color: #fff !important;
		font-size: 16px !important;
		font-weight: bold !important;
	}

	.el-icon.el-collapse-item__arrow {
		color: #91a7c8 !important ;
	}

	.el-collapse-item:last-child .el-collapse-item__header {
		border-bottom: none !important;
	}
	.el-collapse-item:last-child .el-collapse-item__wrap {
		border-bottom: none !important;
	}
	.el-collapse-item__header.is-active {
		border-bottom-color: transparent !important;
	}
</style>
