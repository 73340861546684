import Mixezs from "@/views/Mixezs.vue";


export default {
	message: {
		approve:'授权',
		renounceOwnership:'放弃权限',
		transfer:'转账',
		transferFrom:'从...转账',
		transferOwnership:'转让所有权',
		amount:'数量',
		spender:'支出者',
		sender:'发送者',
		recipient:'接收者',
		newOwner:'新所有者',
		write:"执行",
		addToBlacklist:'添加黑名单',
		disableTransfers:'关闭交易',
		enableTransfers:'开启交易',
		removeFromBlacklist:'移除白名单',
		account:'地址',
		buy:'买入',
		sell:'卖出',
		setpar:'设置LP地址',
		setTeam:'营销费率',
		setpool:'设置加池比例',
		setDividends:'设置分红比例',
		setBurn:'设置销毁比例',
		_amountOu:'数量',
		_Dividendcoins:'持有什么币分红',
		setDividendcoins:'代币地址',
		setDividendswitch:'分红开关',
		enable:'开关',
		_Factory:'工厂合约',
		_PancakeRouter:'路由地址',
		_Teamaddress:'团队地址',
		_par:'交易对地址',
		setFactory:'设置工厂合约',
		setMinimum:'最小持仓',
		setPancakeRouter:'设置路由地址',
		setTeamaddress:'设置团队地址',
		updateBlacklist:'设置黑名单',
		_isBlacklisted:'开关',
		updateTransfers:'交易开关',
		swapTokensAtAmount:'最小卖出金额',
		setswapTokensAtAmount:'设置最小卖出金额',
		setRewardaddress:"设置分红比地址",
		_Rewardaddress:'地址',
		submit: '提交',
		enterContractAddress: '请输入合约地址',
		pleaseEnterValidContractAddress: '请输入有效的合约地址',
		transactionSuccessful: "交易成功",
		contractNotOpenSourceOrInvalidAddressOrWrongChain: '合约未开源,或者合约地址错误,或者链错误'
		},
		dex:{
			swap:'交易',
			addliquidity:'添加流动性',
			removeliquidity:'删除流动性',
			Balance:'余额:',
			ConnectWallet:'链接钱包',
			PleaseEnterAmount: '请输入兑换数量',
			InsufficientLiquidity: '流动性不足',
			InsufficientBalance: '余额不足',
			Authorization: '授权',
			Transaction: '交易',
			PleaseEnterSwapAmount: '请输入兑换数量',
			networkError: "网络错误",
			transactionSuccessful: "交易成功",
			PleaseEnterAddAmount: "请输入添加数量",
			PleaseEnterRemoveAmount: "请输入删除数量",
			PairDoesNotExist: "交易对不存在",
			PleaseEnterTokenAddressOrName: "请输入代币地址或名称",
			CommonTokens: "常用代币",
			AddedByUser: "由用户添加",
			InvalidTokenAddressLength: "代币地址长度错误",
			SlippageLimit: "滑点上限",
			ExpertMode: "专家模式",
			BypassConfirmationAndAllowHighSlippage: "绕过确认模式并允许高滑点交易。使用风险自负。",
			selecttoken: '选择代币',
			settings: 'Settings'
		},
		mint:{
			CreateToken: "创建Token",
			TokenManagement: "Token管理",
			name:"名称:",
			TokenName: "代币名称",
			Symbol:"符号:",
		    TokenSymbol: "代币符号",
			Quantity: "数量:",
		    TotalSupply: "发行数量",
			Decimals: "精度:",
			TradeSwitch: "交易开关:",
			Blacklist: "黑名单:",
			HoldersDividend: "持币分红:",
			PoolDividend: "加池分红:",
			DividendContract: "分红合约:",
			MinHold: "最小持仓:",
			DividendToken: "分红本币:",
			MarketingAddress: "营销地址:",
			buy:"买入:",
			pool:"流动性:",
			marketing:"营销:",
			dividend:"分红:",
			Burn: "销毁:",
			sell:"卖出",
			SelectPoolContract: "请选择加池合约",
			ConnectWallet:'链接钱包',
			address:'合约地址',
			deploySuccess: '部署成功',
			copy: '复制',
			copySuccess: '复制成功',
			networkNotSupported: '暂不支持此网络',
			moreSettings: '更多设置',
			enterContractAddress: '请输入合约地址',
			manage: '管理'
		},
    	Mixezs:{
		   deposit:"存款",
		   Cheque:'凭证',
		   withdraw:'取款',
		   token:'代币',
		   selectToken: '请选择代币',
		   Amount:"数量",
		   selectAmount: '请选择数量',
		   ConnectWallet:'链接钱包',
		   Authorization: '授权',
		   order: '订单',
		   selectDepositOrder: '请选择存款订单',
		   recipient: '取款人',
		   enterRecipientAddress: '请输入收款人地址',
		   generateVoucher: '生成凭证',
		   Proof:'凭证',
		   pleaseEnterProof: '请输入凭证',
		   prooferror:'凭证错误或者您不是接收者,请重新输入',
		   withdrawsuccess: '取款成功',
		   depositsuccess: '存款成功',
		   withdrawproof: '取款凭证',
		   titleone:'Mixezs合约已通过',
		   titletwo:'审计!',
		   titlethree:"查看审计报告!",
		   MixezsPrivacyTransfer: "Mixezs 隐私转账介绍",
		   MixezsIntro: "Mixezs 是一款专注于隐私保护的区块链转账工具，结合了现代密码学技术，保障用户的交易隐私，确保每笔转账都无法被外界追踪。Mixezs 的系统由两个核心智能合约组成：存款合约 (Mixer) 和 验证合约 (Verifier)。",
	       CoreHighlights: "核心亮点",
		   SeamlessPrivacyProtection: "无缝隐私保护：",
		   EffectiveTrackingPrevention: "通过统一的存款金额设计，Mixezs 有效阻止外部对存款来源的识别和跟踪。即便外界能够观察到提款记录，也无法借此逆推出资金的存入者身份。",
		   DeepAnonymityVerification: "深度匿名性验证：",
		   AdvancedSignatureVerification: "系统使用高级的签名验证算法，并结合不可预测的随机数生成技术，全面保护用户隐私。用户身份、交易细节都经过加密和混淆处理，使得任何人无法获取具体的存款和转账信息。",
		   HighlySecureVerificationLogic: "高度安全的验证逻辑：",
		   VerifierContractDesign: "Verifier 合约的核心算法设计，不仅为系统提供了额外的安全防线，也确保了验证过程的完整性。通过内置的密码学验证和防护机制，有效杜绝了潜在的攻击与漏洞利用。",
		   AuditLevelSecurity: "审计级别的安全性：",
		   ThirdPartyAudit: "在上线之前，所有合约均通过了第三方安全机构的严密审计。用户可以完全放心其资金的安全性，任何交易都在隐私不被泄露的前提下进行。",
		   ContractFunctions: "合约功能",
		   DepositContract: "存款合约 (Mixer)：",
		   DepositMechanism: "确保所有用户以相同金额存款，通过资金混合机制打破原始资金的流动轨迹，最大限度保护资金来源隐私。",
		   VerificationContract: "验证合约 (Verifier)：",
		   SignatureVerification: "实现高效的签名验证，基于尖端的密码学机制，合约在确保隐私的同时，也防止了重放攻击和签名伪造。",
		   MixezsAdvantages: "Mixezs 的优势",
		   TopPrivacyProtection: "顶级隐私防护：",
		   InnovativeDesign: "通过创新的合约设计与密码学技术，Mixezs 彻底保障了用户隐私，外界无法通过链上数据进行溯源。",
		   SecurityAndDecentralization: "安全性与去中心化：",
		   RigorousAudit: "经过严格审计的智能合约运行在去中心化区块链上，保证交易安全。",
		   AntiAttackDesign: "防攻击设计：",
		   CryptographicMechanisms: "通过尖端的密码学机制，系统保证了每笔交易的唯一性，彻底防止预测性攻击。"
		},
		nav:{
			swap:'闪兑',
			mint:'铸币',
			Mixezs:'混币'
		}
}
// 自动存款
// 主账户一个
// 需要实现  多账户 自动存款  自动取款

// 1,开始的时候 生成几十个个账号(存款地址)  几十个地址(取款地址)  存入数据库  (地址,私钥,取款凭证,是否有存款)
// 2 自动将代币转给多个用户,给每个账户(存款地址)转入一定数量的代币,和主币(混的不是主币的情况下)
// 3 获取子账户列表  子账户(存款合约)自动将代币 混入合约 (可能会存多笔)
// 注: 用户设置 存款私钥数量和混币地址数量,存款数量,单笔混币数量(存款数量/单笔混笔数量 等于笔数,笔数除以存款私钥数量,多余的给最后一个)


// 自动取款

// 遍历存款私钥  生成取款凭证   使用随机使用收款 账户收款

// 归集

// 遍历收款账户  将资金归集到  指定账户







