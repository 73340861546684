<template>
  <div class="mixer-card">
    <el-form :model="form" label-width="auto">
      <el-form-item :label="$t('Mixezs.Proof')">
        <el-input autocomplete="off" :autosize="{ minRows: 2 }" resize="none" spellcheck="false" v-model="form.Cheque" type="textarea" :placeholder="$t('Mixezs.pleaseEnterProof')" />
      </el-form-item>
      <el-form-item :label="$t('Mixezs.recipient')">
        <el-input autocomplete="off" spellcheck="false" v-model="form.to" :placeholder="$t('Mixezs.enterRecipientAddress')" />
      </el-form-item>
    </el-form>
    <el-button v-if="!isConnected" @click="open()" type="primary">{{ $t('Mixezs.ConnectWallet') }}</el-button>
    <el-button v-else-if="!form.Cheque" disabled type="primary">{{ $t('Mixezs.pleaseEnterProof') }}</el-button>
    <el-button v-else-if="!form.to" disabled type="primary">{{ $t('Mixezs.enterRecipientAddress') }}</el-button>
    <el-button v-else type="primary" @click="Withdraws" :loading="loading">{{ $t('Mixezs.withdraw') }}</el-button>
  </div>
</template>

<script setup>
  import { watch, reactive, ref, onMounted, getCurrentInstance } from 'vue'
  import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/vue'
  import { getprovider } from '@/api/wallet'
  import chainData from '@/assets/mixezs/token.json'
  import { ethers } from 'ethers'
  import CryptoJS from 'crypto-js'
  import { useStore } from 'vuex'
  const { open, close } = useWeb3Modal()
  const store = useStore()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { proxy } = getCurrentInstance()
  const Contract = ref(null)
  const acting = process.env.VUE_APP_ACTING

  const loading = ref(false)
  // do not use same name with ref
  const form = reactive({
    Cheque: '',
    to: '',
  })
  const getdata = async () => {
    try {
      Contract.value = chainData[Number(chainId.value)]['Mixezs']
      if (address.value) {
        form.to = address.value
      }
    } catch (error) {
      if (error.info && error.info.error) {
        const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message
        proxy.$message.error(errorMessage)
      } else {
        proxy.$message.error(error.message)
      }
    }
  }

  const Withdraws = async () => {
    try {
      loading.value = true
      let decryptedText
      try {
        // const bytes = CryptoJS.AES.decrypt(form.Cheque, form.to.toLowerCase());
        const bytes = CryptoJS.AES.decrypt(form.Cheque, address.value.toLowerCase())
        decryptedText = bytes.toString(CryptoJS.enc.Utf8)
      } catch (error) {
        proxy.$message.error(proxy.$t('Mixezs.prooferror'))
        loading.value = false
        return
      }

      if (!decryptedText) {
        proxy.$message.error(proxy.$t('Mixezs.prooferror'))
        loading.value = false
        return
      }

      const data = JSON.parse(decryptedText)
      console.log(data)
      let abi = [
        {
          inputs: [
            {
              components: [
                {
                  internalType: 'address',
                  name: 'relay',
                  type: 'address',
                },
                {
                  internalType: 'address',
                  name: 'token',
                  type: 'address',
                },
                {
                  internalType: 'bytes32',
                  name: 'tohash',
                  type: 'bytes32',
                },
                {
                  internalType: 'bytes',
                  name: 'proof',
                  type: 'bytes',
                },
              ],
              internalType: 'struct mixezs.proof',
              name: 'data',
              type: 'tuple',
            },
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'address',
              name: '_acting',
              type: 'address',
            },
          ],
          name: 'withdraw',
          outputs: [],
          stateMutability: 'payable',
          type: 'function',
        },
      ]
      let contract = new ethers.Contract(Contract.value, abi, await getprovider().getSigner())

      let addre = await contract.withdraw(
        {
          relay: data.relay,
          token: data.token,
          tohash: data.tohash,
          proof: data.proof,
        },
        form.to,
        acting,
      )

      await addre.wait()
      proxy.$message.success(proxy.$t('Mixezs.withdrawsuccess'))
      store.commit('setVerify', false)
      loading.value = false
    } catch (error) {
      loading.value = false
      if (error.info && error.info.error) {
        const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message

        proxy.$message.error(errorMessage)
      } else {
        proxy.$message.error(error.message)
      }
    }
  }

  watch(chainId, (newChainId, oldChainId) => {
    getdata()
  })
  onMounted(() => {
    if (isConnected.value) {
      getdata()
    }
  })
</script>
<style scoped>
  .mixer-card {
    width: 95vw;
    margin: 0 auto;
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: center;
  }
  :deep(.el-textarea__inner) {
    color: #fff !important;
  }
  @media (min-width: 700px) {
    .mixer-card {
      width: 700px;
    }
  }
</style>
<style></style>
