<template>
  <div class="mixer-card">
    <el-form :model="form" label-width="auto" v-loading="getloading" element-loading-background="#040726">
      <el-form-item :label="$t('Mixezs.order')">
        <el-select v-model="form.info" :placeholder="$t('Mixezs.selectDepositOrder')">
          <el-option v-for="item in cities" :key="item.nonce" :label="item.symbol" :value="item">
            <div class="tookenlist">
              <el-avatar size="small" :src="item.token ? 'https://webgallery.oss-cn-beijing.aliyuncs.com/token/' + chainId + '/' + item.token.toLowerCase() + '.png' : ''" />

              <li class="tokenlist" style="color: #fff; font-size: 13px">
                <span>{{ item.symbol }}</span>
                <span>{{ item.amount }}</span>
                <span>{{ item.time }}</span>
              </li>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Mixezs.recipient')">
        <el-input v-model="form.to" autocomplete="off" spellcheck="false" :placeholder="$t('Mixezs.enterRecipientAddress')" />
      </el-form-item>
    </el-form>
    <setcheque ref="childRef" />
    <el-button v-if="!isConnected" @click="open()" type="primary">{{ $t('Mixezs.ConnectWallet') }}</el-button>
    <el-button v-else-if="!form.info.token" disabled type="primary">{{ $t('Mixezs.selectDepositOrder') }}</el-button>
    <el-button v-else-if="!form.to" disabled type="primary">{{ $t('Mixezs.enterRecipientAddress') }}</el-button>
    <el-button v-else @click="callChildMethod" type="primary">{{ $t('Mixezs.generateVoucher') }}</el-button>
  </div>
</template>

<script setup>
  import { computed, watch, reactive, ref, onMounted, getCurrentInstance } from 'vue'
  import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/vue'
  import { ethers } from 'ethers'
  import { getprovider } from '@/api/wallet'
  import Decimal from 'decimal.js'
  import setcheque from '@/components/mixer/setcheque'
  import { v4 as uuidv4 } from 'uuid'
  import { useStore } from 'vuex'
  import chainData from '@/assets/mixezs/token.json'
  const { open, close } = useWeb3Modal()
  const store = useStore()
  const { proxy } = getCurrentInstance()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const childRef = ref(null)
  // do not use same name with ref
  const form = reactive({
    info: '',
    to: '',
  })
  const getloading = ref(false)
  const cities = computed(() => store.state.orderlist)
  const loading = ref(false)
  let Contract = null
  const decimals = ref(null)

  const verify = computed(() => store.state.verify)
  const msgHash = computed(() => store.state.singhash)
  const signature = computed(() => store.state.singtext)

  const getdata = async () => {
    try {
      getloading.value = true

      if (!isConnected.value) {
        proxy.$message.error(proxy.$t('Mixezs.ConnectWallet'))
      }

      Contract = chainData[Number(chainId.value)]['Mixezs']

      if (!verify.value) {
        await Verifier()
      }

      getloading.value = false
    } catch (error) {
      console.log(error.message)
      getloading.value = false
      if (error.info && error.info.error) {
        const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message
        proxy.$message.error(errorMessage)
      } else {
        proxy.$message.error(error.message)
      }
    }
  }

  const Verifier = async () => {
    let abi = [
      {
        inputs: [
          {
            internalType: 'bytes',
            name: '_msg',
            type: 'bytes',
          },
          {
            internalType: 'bytes',
            name: 'signature',
            type: 'bytes',
          },
        ],
        name: 'getMyData',
        outputs: [
          {
            components: [
              {
                internalType: 'uint256',
                name: 'nonce',
                type: 'uint256',
              },
              {
                internalType: 'bytes32',
                name: 'tohash',
                type: 'bytes32',
              },
              {
                internalType: 'string',
                name: 'symbol',
                type: 'string',
              },
              {
                internalType: 'uint256',
                name: 'decimals',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'token',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'time',
                type: 'uint256',
              },
            ],
            internalType: 'struct Mixezs.DataItem[]',
            name: '',
            type: 'tuple[]',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
    ]

    if (!msgHash.value || !signature.value) {
      const account = uuidv4()
      let msgHash = ethers.solidityPackedKeccak256(['string'], [account])
      const messageHashBytes = ethers.getBytes(msgHash)
      const signer = await getprovider().getSigner()
      let signature = await signer.signMessage(messageHashBytes)
      store.commit('setSinghash', msgHash)
      store.commit('setSingtext', signature)
    }

    let contract = new ethers.Contract(Contract, abi, await getprovider().getSigner())

    let list = await contract.getMyData(msgHash.value, signature.value)
    const sortedList = [...list].sort((a, b) => Number(a[6]) - Number(b[6]))
    const orlist = sortedList.map((item) => ({
      nonce: item[0].toString(),
      tohash: item[1].toString(),
      symbol: item[2],
      token: item[4],
      amount: ethers.formatUnits(item[5], item[3]),
      time: new Date(Number(item[6]) * 1000).toLocaleString(),
    }))
    store.commit('setOrderlist', orlist)
    store.commit('setVerify', true)
  }

  const callChildMethod = () => {
    if (childRef.value) {
      childRef.value.Mixer(Contract, form.info.token, form.to, form.info.nonce, form.info.tohash)
    }
  }
  watch(chainId, (newChainId, oldChainId) => {
    getdata()
  })
  onMounted(() => {
    if (isConnected.value) {
      getdata()
    }
  })
</script>
<style scoped>
  .mixer-card {
    width: 95vw;
    margin: 0 auto;
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: center;
  }
  @media (min-width: 700px) {
    .mixer-card {
      width: 700px;
    }
  }

  :deep(.tokenlist) {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
</style>
<style>
  .mixer-card .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .tookenlist {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
  }
</style>
