<template>
  <div>
    <el-dialog v-if="isDesktop" v-model="dialogVisible" title="取款凭证" width="500" :before-close="handleClose">
      <div class="setcheque">
        <el-form :model="form" label-width="auto">
          <el-form-item label="Cheque:">
            <el-input autocomplete="off" resize="none" spellcheck="false" v-model="form.Cheque" disabled autosize type="textarea" placeholder="Please input" />
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="copy">copy</el-button>
      </div>
    </el-dialog>
    <el-drawer v-else v-model="dialogVisible" direction="btt" size="500" :before-close="handleClose">
      <template #header>
        <span class="title">{{ $t('Mixezs.withdrawproof') }}</span>
      </template>
      <div class="setcheque">
        <el-form :model="form" label-width="auto">
          <el-form-item label="Cheque:">
            <el-input autocomplete="off" resize="none" spellcheck="false" v-model="form.Cheque" disabled autosize type="textarea" placeholder="Please input" />
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="copy">copy</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
  import { reactive, ref, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue'
  import { useWeb3ModalAccount } from '@web3modal/ethers/vue'
  import { ethers } from 'ethers'
  import { getprovider } from '@/api/wallet'
  import Decimal from 'decimal.js'
  import CryptoJS from 'crypto-js'
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { proxy } = getCurrentInstance()
  const isDesktop = ref(window.matchMedia('(min-width: 700px)').matches)
  const dialogVisible = ref(false)
  const mediaQuery = ref(null)
  const form = reactive({
    Cheque: '',
  })
  const handleClose = () => {
    dialogVisible.value = false
  }

  const Mixer = async (Contract, token, to, nonce, tohash) => {
    try {
      const domain = {
        name: 'mixezs',
        version: '1',
        verifyingContract: Contract,
        salt: ethers.zeroPadValue(ethers.hexlify(ethers.toBeHex(chainId.value)), 32),
      }

      const types = {
        mixezs: [
          { name: 'relay', type: 'address' },
          { name: 'token', type: 'address' },
          { name: 'nonce', type: 'uint256' },
        ],
      }
      const message = {
        relay: to,
        token: token,
        nonce: nonce.toString(),
      }
      const signer = await getprovider().getSigner()
      const signature = await signer.signTypedData(domain, types, message)
      let json = JSON.stringify({ relay: to, token: token, tohash: tohash, proof: signature })

      const password = to.toLowerCase()

      form.Cheque = CryptoJS.AES.encrypt(json, password).toString()
      dialogVisible.value = true
    } catch (error) {
      if (error.info && error.info.error) {
        const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message
        proxy.$message.error(errorMessage)
      } else {
        proxy.$message.error(error.message)
      }
    }
  }

  const copy = async () => {
    try {
      navigator.clipboard
        .writeText(form.Cheque)
        .then(() => {
          proxy.$message.success('copy success')
        })
        .catch((err) => {
          proxy.$message.error('copy error')
        })
    } catch (error) {}
  }

  const handleResize = (event) => {
    isDesktop.value = event.matches
  }

  onMounted(() => {
    mediaQuery.value = window.matchMedia('(min-width: 700px)')
    mediaQuery.value.addEventListener('change', handleResize)
  })

  onBeforeUnmount(() => {
    if (mediaQuery.value) {
      mediaQuery.value.removeEventListener('change', handleResize)
    }
  })

  defineExpose({
    Mixer,
  })
</script>
<style>
  .setcheque {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
</style>
