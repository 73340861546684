<template>
  <router-view />
</template>
<style>
  html,
  body {
    box-sizing: border-box;
    font-size: calc(100vw / 3.75);
    margin: 0;
    padding: 0;
    overflow-x: hidden;

    min-height: 100%;
    height: auto;
  }
  body {
    position: relative;
    font-size: 0.16rem;
    margin: 0;
    padding: 0;
    border: none;

    background: #040726;

    user-select: none;
  }
  * {
    box-sizing: border-box;
  }
  wui-flex {
    padding: 4px 0px 4px 6px !important;
  }
  :root {
    --wui-color-gray-glass-015: rgba(255, 255, 255, 0.712) 1 !important;
    --wui-color-accent-100: #1249ec !important;
    --wui-color-accent-090: none !important;
    --wui-color-fg-200: #ffffff !important;
    --wui-spacing-m: 4px !important;
    /* --wui-spacing-xs: 10px !important; */
    --w3m-accent: none !important;
    --foot-back: #252c41;
    --primary-color: #1249ec !important;
    --hcan1-color: #13c07e !important;
    --hcan2-color: #00e766 !important;
    --bac-background: #f8f8f8 !important;
    --border-color: #f2f6fc !important;
    --el-color-primary: #1249ec !important;
    --el-color-primary-dark-2: #108560 !important;
    --el-color-primary-light-3: #4ad991 !important;
    --el-color-primary-light-5: #7edfbf !important;
    --el-color-primary-light-7: #a4e6d3 !important;
    --el-color-primary-light-8: #b7edda !important;
    --el-color-primary-light-9: #cbf5e1 !important;
    --el-border-color: #33344e !important;
  }

  .el-textarea__inner,
  .el-textarea.is-disabled .el-textarea__inner {
    background-color: #121331 !important;
    box-shadow: 0 0 0 1px #33344e inset !important;
  }
  .el-select__placeholder {
    color: #fff !important;
  }
  .el-input__wrapper {
    box-shadow: 0 0 0 1px #33344e inset !important;
    background-color: #121331 !important;
  }
  .el-input__inner {
    color: #ffffff !important;
  }
  .el-textarea__inner:hover,
  .el-input__wrapper:hover {
    box-shadow: 0 0 0 1px #3b82f6 inset !important;
  }
  .el-tag.el-tag--danger {
    --el-tag-text-color: #040726 !important;
    --el-tag-bg-color: #1ed8e1 !important;
    --el-tag-border-color: #1ed8e1 !important;
    --el-tag-hover-color: #040726 !important;
  }

  .el-tag.el-tag--success {
    --el-tag-text-color: #fff !important;
    --el-tag-bg-color: #01315f !important;
    --el-tag-border-color: #01315f !important;
    --el-tag-hover-color: #fff !important;
  }
  .el-button {
    background: linear-gradient(90deg, #803dff 0%, #1541fa 100%) !important;
    box-shadow: 0px 10px 20px 1px rgba(102, 58, 248, 0.44) !important;
    border-radius: 10px 10px 10px 10px !important;
    border: 0 !important;
    font-size: 16px !important;
    font-weight: bold !important;
    height: 50px !important;
  }
  .el-button.is-disabled,
  .el-button.is-disabled:hover {
    background: linear-gradient(90deg, #803dff 0%, #1541fa 100%) !important;
    position: relative;
    overflow: hidden;
  }
  .el-form-item__label {
    color: #fff !important;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    background: #121331 !important ;
    color: #91a7c8 !important;
  }
  .el-dialog {
    --el-dialog-bg-color: #121331 !important;
    border-radius: 10px !important;
  }
  .el-dialog__title {
    color: #fff !important;
  }
  .el-drawer {
    --el-drawer-bg-color: #121331 !important;
    border-radius: 20px 20px 0 0;
  }
  .el-select__wrapper {
    background-color: #121331 !important;
  }

  .el-button.is-disabled::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.3;
    pointer-events: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .el-popper {
    /* display: block !important; */
    border: none !important;
    box-shadow: none !important;
    color: #121331 !important;
  }

  .el-select-dropdown {
    background-color: #121331 !important;
    border: 1px solid #33344e !important;
    border-radius: 4px !important;
  }

  .el-select-dropdown__item {
    color: #ffffff !important;
    padding: 0 15px !important;
  }
  .el-select-dropdown__item:hover {
    background-color: #1e90ff !important;
  }
  .el-select-dropdown__item.is-hovering {
    background-color: #1e90ff !important;
  }

  .el-popper.is-light .el-popper__arrow:before {
    background: #121331 !important;
    border: 1px solid #121331 !important;
    right: 0;
  }
  .Linkwallet {
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    background: rgba(20, 20, 20, 0.8);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 625px) {
    body {
      font-size: 26.2125px;
    }
  }
</style>
<!-- https://github.com/{OWNER}/{REPO}/issues/new?title={TITLE}&body={BODY} -->
