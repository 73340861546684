<template>
  <div class="mixer-card">
    <el-form :model="form" label-width="auto" v-loading="getloading" element-loading-background="#040726">
      <el-form-item :label="$t('Mixezs.token')">
        <el-select v-model="form.token" @change="getAMOUT" :placeholder="$t('Mixezs.selectToken')">
          <el-option class="eloption" v-for="(item, key) in cities" :key="key" :label="key" :value="item.address">
            <div class="tookenlist">
              <el-avatar size="small" :src="item.address ? 'https://webgallery.oss-cn-beijing.aliyuncs.com/token/' + chainId + '/' + item.address.toLowerCase() + '.png' : ''" />
              <span style="font-size: 14px">
                {{ key }}
              </span>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Mixezs.Amount')">
        <el-select v-model="form.amount" @change="isAuthorize" :label="$t('Mixezs.selectAmount')" :open="true">
          <el-option v-for="(item, index) in AMOUT" :key="index" :label="item" :value="item" />
        </el-select>
      </el-form-item>
    </el-form>
    <setcheque ref="childRef" />
    <el-button v-if="!isConnected" @click="open()" type="primary">{{ $t('Mixezs.ConnectWallet') }}</el-button>
    <el-button v-else-if="!form.token" disabled type="primary">{{ $t('Mixezs.selectToken') }}</el-button>
    <el-button v-else-if="!form.amount" disabled type="primary">{{ $t('Mixezs.selectAmount') }}</el-button>
    <el-button v-else-if="!Authorizeornot" :loading="loading" @click="Authorize" type="primary">{{ $t('Mixezs.Authorization') }}</el-button>
    <el-button v-else-if="Authorizeornot" :loading="loading" @click="trade" type="primary">{{ $t('Mixezs.deposit') }}</el-button>
  </div>
</template>

<script setup>
  import { watch, reactive, ref, onMounted, getCurrentInstance } from 'vue'
  import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/vue'
  import setcheque from '@/components/mixer/setcheque'
  import { ethers } from 'ethers'
  import { getprovider, getEthBalance, Checktokenbalance, truncateDecimal } from '@/api/wallet'
  import Decimal from 'decimal.js'
  import { useStore } from 'vuex'
  import chainData from '@/assets/mixezs/token.json'
  const { open, close } = useWeb3Modal()
  const store = useStore()
  const { proxy } = getCurrentInstance()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const childRef = ref(null)
  const getloading = ref(false)
  const WETH = ref(null)
  // do not use same name with ref
  const form = reactive({
    token: '',
    amount: '',
  })

  const cities = ref([])

  const AMOUT = ref([0.1, 1, 10, 100, 1000])

  const loading = ref(false)
  const Authorizeornot = ref(false)

  let Contract = null
  const decimals = ref(null)
  const getdata = async () => {
    try {
      getloading.value = true
      if (!isConnected.value) {
        proxy.$message.error(proxy.$t('Mixezs.ConnectWallet'))
      }
      Contract = chainData[Number(chainId.value)]['Mixezs']
      WETH.value = chainData[Number(chainId.value)]['nativetoken']
      cities.value = chainData[Number(chainId.value)]['tokenlist']
      form.token = chainData[Number(chainId.value)]['nativetoken']
      getAMOUT()
      getloading.value = false
    } catch (error) {
      getloading.value = false
      proxy.$message.error(error.message)
    }
  }

  const getAMOUT = () => {
    if (!isConnected.value) {
      return
    }
    let obj = chainData[Number(chainId.value)]['tokenlist']
    for (const key in obj) {
      if (form.token == obj[key]['address']) {
        AMOUT.value = obj[key]['quantity']
        form.amount = AMOUT.value[0] ? AMOUT.value[0] : ''
        isAuthorize()
      }
    }
  }

  const isAuthorize = async () => {
    try {
      if (!isConnected.value) {
        return
      }

      if (!form.token || !form.amount / 1 > 0) {
        return
      }

      loading.value = true
      if (form.token == WETH.value) {
        Authorizeornot.value = true
      } else {
        if (form.amount > 0 && address.value) {
          let abi = ['function decimals() view returns (uint8)', ' function allowance(address owner,address spender) external view returns (uint256)']
          let contract = new ethers.Contract(form.token, abi, getprovider())

          let allowance = await contract.allowance(address.value, Contract)

          decimals.value = await contract.decimals()
          let balanceOf = ethers.formatUnits(allowance, decimals.value)

          if (balanceOf / 1 >= form.amount / 1) {
            Authorizeornot.value = true
          } else {
            Authorizeornot.value = false
          }
        }
      }

      loading.value = false
    } catch (error) {
      loading.value = false
      if (error.info && error.info.error) {
        const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message
        proxy.$message.error(errorMessage)
      } else {
        proxy.$message.error(error.message)
      }
    }
  }

  const Authorize = async () => {
    try {
      if (!isConnected.value) {
        proxy.$message.error(proxy.$t('Mixezs.ConnectWallet'))
        return
      }

      loading.value = true
      let abi = ['function approve(address spender, uint256 amount) external returns (bool)']
      let contract = new ethers.Contract(form.token, abi, await getprovider().getSigner())
      let amountOut = ethers.parseUnits(form.amount.toString(), decimals.value)
      let tx = await contract.approve(Contract, amountOut)
      await tx.wait()
      loading.value = false
      isAuthorize()
    } catch (error) {
      loading.value = false
      if (error.info && error.info.error) {
        const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message
        proxy.$message.error(errorMessage)
      } else {
        proxy.$message.error(error.message)
      }
    }
  }
  const trade = async () => {
    try {
      loading.value = true
      let abi = ['function deposit(address token, uint256 amount)']
      let contract = new ethers.Contract(Contract, abi, await getprovider().getSigner())

      if (form.token != WETH.value) {
        let amountOut = ethers.parseUnits(form.amount.toString(), decimals.value)
        let tx = await contract.deposit(form.token, amountOut)
        await tx.wait()
      } else {
        let amountToSend = ethers.parseUnits(form.amount.toString())
        let tx = await contract.deposit(form.token, amountToSend, {
          value: amountToSend,
        })
        await tx.wait()
      }
      proxy.$message.success(proxy.$t('Mixezs.depositsuccess'))
      loading.value = false
      store.commit('setVerify', false)
    } catch (error) {
      if (error.info && error.info.error) {
        const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message
        proxy.$message.error(errorMessage)
      } else {
        proxy.$message.error(error.message)
      }
      loading.value = false
    }
  }
  watch(chainId, (newChainId, oldChainId) => {
    getdata()
  })
  onMounted(() => {
    if (isConnected.value) {
      getdata()
    }
  })
</script>
<style scoped>
  .mixer-card {
    width: 95vw;
    margin: 0 auto;

    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: center;
  }
  @media (min-width: 700px) {
    .mixer-card {
      width: 700px;
    }
  }
</style>
<style>
  .mixer-card .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .tookenlist {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
  }
</style>
