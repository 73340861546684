
import { modal } from '@/api/web3ModalConfig'
import { ethers } from 'ethers'
import { ElMessage } from 'element-plus'



function getprovider() {
    let provider = null

    try {

        const walletProvider = modal.getWalletProvider()
        provider = new ethers.BrowserProvider(walletProvider)



    } catch (error) {
        if (error.info && error.info.error) {
            const errorMessage = error.info.error.data && error.info.error.data.message ? error.info.error.data.message : error.info.error.message

            ElMessage.error(errorMessage)
        } else {
            ElMessage.error(error.message)
        }
    }
    return provider
}



async function getEthBalance(address) {
    try {
        const walletProvider = getprovider();
        const ethBalance = await walletProvider.getBalance(address);
        let balance = ethers.formatEther(ethBalance);
        return balance
    } catch (error) {
        ElMessage.error(error.message)
    }
}

async function Checktokenbalance(address, token) {
    try {
        let abi = ['function decimals() view returns (uint8)', ' function balanceOf(address account) view returns (uint256)']
        let contract = new ethers.Contract(token, abi, getprovider())
        let tx = await contract.balanceOf(address)
        let decimals = await contract.decimals()
        let balanceOf = ethers.formatUnits(tx, decimals)
        return balanceOf.toString()
    } catch (error) {

        console.log(error.message);
        return '0.0'
    }

}

function truncateDecimal(numberString, subs = 3) {
    const [integerPart, decimalPart] = numberString.split('.')
    let result = integerPart
    if (decimalPart !== undefined) {

        const truncatedDecimal = decimalPart.substring(0, subs)
        result += `.${truncatedDecimal}`
    }
    return result
}
export { getprovider, getEthBalance, Checktokenbalance, truncateDecimal };