<template>
  <div class="mixerbox">
    <div class="box-cards">
      <el-menu :default-active="activeIndex" class="custom-menu" :ellipsis="false" mode="horizontal" background-color="#121331" text-color="#91A7C8" active-text-color="#FFFFFF">
        <el-menu-item @click="radio2 = 'deposit'" index="1">{{ $t('Mixezs.deposit') }}</el-menu-item>
        <el-menu-item @click="radio2 = 'Cheque'" index="2">{{ $t('Mixezs.Proof') }}</el-menu-item>
        <el-menu-item @click="radio2 = 'withdraw'" index="3">{{ $t('Mixezs.withdraw') }}</el-menu-item>
      </el-menu>
      <deposit v-if="radio2 == 'deposit'" />
      <Cheque v-else-if="radio2 == 'Cheque'" />
      <withdraw v-else-if="radio2 == 'withdraw'" />
    </div>
  </div>
</template>
<script setup>
  import { watch, ref, onMounted, onUnmounted, getCurrentInstance } from 'vue'
  import deposit from '@/components/mixer/deposit'
  import Cheque from '@/components/mixer/Cheque'
  import withdraw from '@/components/mixer/withdraw'
  const radio2 = ref('deposit')
  const activeIndex = ref('1')
</script>

<style scoped>
  .mixerbox {
    padding-top: 20px;
    margin: auto;
    font-size: 20px;
  }

  @media (min-width: 700px) {
    .mixerbox {
      width: 700px;
    }
  }
</style>
<style>
  .mixerbox .box-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    gap: 20px;
  }
</style>
<style scoped>
  .custom-menu {
    display: flex;
    justify-content: space-around;
  }
  .custom-menu .el-menu-item {
    flex: 1;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 2px solid #1249ec !important;
  }
  .el-menu--horizontal.el-menu {
    border-bottom: 0 !important;
  }
</style>
